<div class="row bg-cpright py-3">
    <div class="col-xl-12 text-center">
        <!-- <span>Copyright © {{currentYear}} Samvaad LMS. All Rights Reserved. Powered by
            <a href="https://dhanushhealthcare.com/index.html" target="_blank">Dhanush Healthcare Systems</a>
        </span> -->
        <!-- <span>Copyright © {{currentYear}} OUK. All Rights Reserved. Powered by
            <a href="javascript:void(0)">The Open University Kenya</a>
        </span> -->
        <span>Copyright © {{currentYear}} Dhanush Infotech. All Rights Reserved.</span>
    </div>
</div>
<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">{{headingName.UserRegistrationApprovalList}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <!-- <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add</button>
                                        </div> -->

                                        <div class="pt-2 table-responsive" *ngIf="table.length">
                                            <table datatable id="DataTables_Table_0"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Full Name </th>
                                                        <!-- <th>Last Name</th> -->
                                                        <!-- <th>Email Id</th> -->
                                                        <th>Program Name</th>
                                                        <th>Contact Details</th>
                                                        <th>Application Form</th>
                                                        <th>Uploaded Documents</th>
                                                        <th>Verification Status</th>
                                                        <th>Application Status</th>
                                                        <th>Admission Status</th>
                                                        <th>Application Date</th>
                                                        <th>Action</th>

                                                        <!-- <th>Mobile Number</th>
                                                        <th>Application Form</th>
                                                        <th>Action</th>
                                                        <th>Uploaded Documents</th> -->

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.firstname}} {{item.lastname}}</td>
                                                        <!-- <td>{{item.lastname}}</td> -->
                                                        <!-- <td>{{item.name}}</td> -->
                                                        <th>{{item.course_name}}</th>
                                                        <td>{{item.mobileno_code_name}} {{item.mobileno}}</td>


                                                        <td>
                                                            <button class="btn btn-save btn-sm"
                                                                data-bs-target="#applicationPopup"
                                                                data-bs-toggle="modal"
                                                                (click)="userDetailsMethod(item)">View
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-sm btn-info" data-bs-toggle="modal"
                                                                data-bs-target="#myModal"
                                                                (click)="getDocuments(item)">View</button>

                                                        </td>
                                                        <td>
                                                            {{item.verification_status}}
                                                        </td>
                                                        <td>
                                                            {{item.application_status}}
                                                        </td>
                                                        <td>
                                                            {{item.is_approved}}
                                                        </td>
                                                        <td>
                                                            {{item.application_date}}
                                                        </td>
                                                        <td style="width:8% !important;">
                                                            <button class="btn btn-save btn-sm" data-bs-toggle="modal"
                                                                data-bs-target="#reason" (click)="userData(item)"
                                                                [attr.disabled]="(item.verification_status=='VERIFIED') && (item.application_status=='SHORTLISTED') && (item.is_approved=='ADMISSIBLE')?true:null"><i
                                                                    class="fas fa-edit"></i> Action</button>



                                                            <!-- <div class="button-group d-none">
                                                                <button class="btn btn-success btn-sm"
                                                                    (click)="ApproveUser(1,item)">Admissible </button>
                                                                <button class="btn btn-primary btn-sm"
                                                                    data-bs-toggle="modal" data-bs-target="#reason"
                                                                    (click)="reject(3,item)">Feedback </button>
                                                                <button class="btn btn-danger btn-sm"
                                                                    data-bs-toggle="modal" data-bs-target="#reason"
                                                                    (click)="reject(2,item)">Not
                                                                    Admissible</button>
                                                            </div> -->
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" tabindex="-1" id="myModal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="close()"></button>
            </div>
            <div class="modal-body">
                <table datatable id="DataTables_Table_0" class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                    style="width:100%">
                    <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                        <tr>
                            <th> Education Supporting Documents </th>
                            <!-- <th>High School Transcript</th>
                            <th>National ID/Passport</th> -->
                            <!-- <th>Passport Size Photograph</th> -->
                            <th>Portfolio</th>
                            <!-- <th>Application Essay</th> -->


                        </tr>
                        <!-- <tr></tr>
                            <tr></tr> -->
                    </thead>
                    <tbody>
                        <tr>
                            <td><button class="btn btn-info btn-sm"
                                    (click)="download(uploadRes?.upload_1)">View</button></td>
                            <!-- <td><button class="btn btn-info btn-sm"
                                    (click)="download(uploadRes?.upload_2)">Download</button></td>
                            <td><button class="btn btn-info btn-sm"
                                    (click)="download(uploadRes?.upload_3)">Download</button></td> -->
                            <!-- <td><button class="btn btn-info btn-sm"
                                    (click)="download(uploadRes?.upload_4)">View</button></td> -->
                            <td>
                                <b *ngIf="uploadRes?.upload_3 == null">Not Uploaded</b>
                                <button class="btn btn-info btn-sm" *ngIf="uploadRes?.upload_3 !=null"
                                    (click)="download(uploadRes?.upload_3)">View</button>
                            </td>
                            <!-- <td><button class="btn btn-info btn-sm"
                                    (click)="download(uploadRes?.upload_5)">Download</button></td> -->





                        </tr>
                        <!-- <tr>
                                <td>dd</td>
                            </tr>
                            <tr>
                                <td>ff</td>
                            </tr> -->
                    </tbody>
                </table>
            </div>
            <!-- <div *ngIf="audioData">
                    <audio controls id="audioPause">
                        <source [src]="audioList">
                    </audio>
                </div> -->
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="md_close"
                    (click)="close()">Close</button>
                <!-- <button type="button" class="btn btn-primary">Save</button> -->
            </div>
        </div>
    </div>
</div>

<!-- <button data-bs-target="reason" data-bs-toggle="modal" id="rejectReason"></button> -->
<div class="modal" tabindex="-1" id="reason">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="Close" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="StatusForm" (onSubmit)="callApiAndUpdateUI(StatusForm)">
                    <div class="row">
                        <!-- <div class="col-xl-12"> -->
                        <div class="col-xl-6">
                            <label class="form-label">Verification Status</label>
                            <select class="form-control form-control-sm"
                                [attr.disabled]="Verification == '5' ? true:null || userObj?.verification_status=='VERIFIED'?true:null"
                                [(ngModel)]="Verification" #vs formControlName="VERIFICATION_STATUS">
                                <!-- <option value="">Select</option> -->
                                <option value="0">Unverified</option>
                                <option value="5">Verified</option>
                                <option value="3">Follow Up</option>

                            </select>

                        </div>
                        <div class="col-xl-6">
                            <label class="form-label">Shortlisting Status</label>
                            <select class="form-control form-control-sm" formControlName="APPLICATION_STATUS"
                                [attr.disabled]="vs.value !='5'?true:null || ss.value == '1'?true:null  || userObj?.application_status=='SHORTLISTED'?true:null"
                                [(ngModel)]="shortListing" #ss>
                                <option value="0">Select</option>
                                <option value="1">Shortlisted</option>
                                <option value="2">Not Shortlisted</option>
                            </select>

                        </div>
                        <!-- <div class="col-xl-6">
                            <label class="form-label">Admission Status</label>
                            <select class="form-control form-control-sm pt-2" [(ngModel)]="admissionStatus"
                                formControlName="IS_APPROVED"
                                [attr.disabled]="ss.value !='1'?true:null || admissionStatus==1?true:null || userObj?.is_approved=='ADMISSIBLE'?true:null">
                            
                                <option value="0">Select</option>
                                <option value="1">Admissible</option>
                                <option value="2">Not Admissible</option>
                            </select>
                        </div> -->

                    </div>
                    <div class="mb-3" *ngIf="Verification == 3">
                        <label for="exampleFormControlTextarea1" class="form-label">Comments</label><span
                            class="text-danger">*</span>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="5"
                            formControlName="COMMENTS"></textarea>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="close()"
                    id="Close">Close</button>
                <button type="button" class="btn btn-save" (click)="callApiAndUpdateUI(StatusForm)">Submit</button>

            </div>
        </div>
    </div>
</div>



<div class="modal" tabindex="-1" id="applicationPopup">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Application Details</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" id="ClosePopup"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div id="printtable" class="pt-5">


                    <table width="980" cellspacing="0" cellpadding="0" style="margin: auto;">
                        <tbody>
                            <tr>
                                <td>
                                    <table width="980" border="0" cellspacing="10" cellpadding="0">
                                        <tbody>
                                            <tr>
                                                <td height="" style="text-align: center; " valign="middle"><img
                                                        src="../../../../assets/img/ouk-logo-new.png" width="200"
                                                        alt=""></td>
                                                <td rowspan="4">
                                                    <div class="pt-2 d-flex" style="width: 150px; height: 150px;">
                                                        <img [src]="userDetails?.result?.upload_4"
                                                            class="img-fluid m-auto"
                                                            style="margin: auto; max-height: 100%;" alt="">
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center; ">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center; ">
                                                    <table width="100%">
                                                        <tr>
                                                            <td width="35%">
                                                                <p style="margin: 0px; font-size: 12px !important;">
                                                                    <b>P.O. BOX</b> 2440-00606 NAIROBI, KENYA
                                                                </p>
                                                            </td>
                                                            <td width="35%">
                                                                <p style="margin: 0px; font-size: 12px !important;">
                                                                    <b>TELEPHONE:</b> 0202000211/0202000212
                                                                </p>
                                                            </td>
                                                            <td width="30%">
                                                                <p style="margin: 0px; font-size: 12px !important;">
                                                                    <b>EMAIL:</b> admissions@ouk.ac.ke
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center; ">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center; ">
                                                    <h4 style="margin-top: 0; margin-bottom: 10px; font-weight: 600;">
                                                        OUK Admissions Office</h4>
                                                    <h5 style="color: #fe7f50; font-weight: 500">STUDENT APPLICATION
                                                        ONLINE FORM</h5>
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </td>

                            </tr>


                            <tr>
                                <td colspan="2" style="padding: 10px">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                        style="border-top:2px dashed #333; font-size:13px;">
                                        <tbody>
                                            <tr>
                                                <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                    <h5><b>(A) PERSONAL DETAILS</b></h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="24%"><b>First Name</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.firstname}}</td>
                                                <td width="24%"><b>Middle Name</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.middlename}}</td>

                                            </tr>
                                            <tr>
                                                <td width="24%"><b>Last Name</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.lastname}}</td>
                                                <td><b>Country of Residence</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.country_of_residence}}</td>
                                            </tr>

                                            <tr>

                                                <td *ngIf="userDetails?.result?.counties_id !=0"><b>County</b></td>
                                                <td *ngIf="userDetails?.result?.counties_id !=0" width="2%"
                                                    style="text-align: center;">:</td>
                                                <td *ngIf="userDetails?.result?.counties_id !=0">
                                                    {{userDetails?.result?.counties_name}}</td>


                                                <td><b>Postal Address</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.postal_address}} </td>


                                            </tr>
                                            <tr>
                                                <td><b>Physical Address</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.physical_address}}</td>
                                                <td><b>Postal Code</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.postal_code}}</td>



                                            </tr>

                                            <tr>
                                                <td><b>Town/City</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.nearest_town}}</td>
                                                <td width="24%"><b>Current Email</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.username}}</td>


                                            </tr>

                                            <tr>
                                                <td><b>Mobile Number 1</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.mobileno_code_name}}
                                                    {{userDetails?.result?.mobileno}}</td>
                                                <td><b>Mobile Number 2</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.mobileno2_code_name}}
                                                    {{userDetails?.result?.mobileno2}}</td>

                                            </tr>
                                            <tr>
                                                <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                    <h5><b>(B) Biographical Information</b></h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="24%"><b>Gender</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.gender_name}}</td>
                                                <td width="24%"><b>Date of Birth</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.dob}}</td>
                                            </tr>
                                            <tr>
                                                <td width="24%"><b>Marital Status</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.maritalstatus_name}}</td>
                                                <td width="24%"><b>Birth Country</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td width="24%">{{userDetails?.result?.birth_country}}</td>
                                            </tr>
                                            <tr>

                                                <td><b>Nationality</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.nan_code}}</td>
                                                <td><b>Identification Document Type</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.id_passport_type_name}}</td>
                                            </tr>
                                            <tr>


                                                <td><b>{{userDetails?.result?.id_passport_type_name}}</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td colspan="4">{{userDetails?.result?.id_passport}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                        style="font-size:13px;">
                                        <tbody>
                                            <tr>
                                                <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                    <h5><b>(C) Next of Kin/Emergency Contact :</b></h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Full Name</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.name_of_emergency}}</td>
                                                <td><b>Relationship</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.relationshipname_of_emergency_name}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Telephone</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.emergency_mobileno_code_name}}
                                                    {{userDetails?.result?.mobile_no_emergency}}</td>
                                                <td><b>Email Address</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.emailid_of_emergency}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Postal Address</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.address_of_emergency}}</td>
                                                <td><b>Postal Code</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.postalcode_of_emergency}}</td>

                                            </tr>
                                            <tr>
                                                <td><b>City/Town</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.
                                                    nearest_town}}</td>
                                                <td><b>Country</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.emergency_country}}</td>

                                            </tr>
                                            <tr>

                                                <td *ngIf="userDetails?.result?.counties_id_of_emergency !=0">
                                                    <b>County</b>
                                                </td>
                                                <td *ngIf="userDetails?.result?.counties_id_of_emergency !=0" width="2%"
                                                    style="text-align: center;">:</td>
                                                <td *ngIf="userDetails?.result?.counties_id_of_emergency !=0">
                                                    {{userDetails?.result?.counties_name_of_emergency}}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                        style="border-bottom:1px dashed #333; font-size:13px;">
                                        <tbody>
                                            <tr>
                                            <tr style="border-bottom:0px dashed #333; font-size:13px;">
                                                <td colspan="6">
                                                    <h5><b>(D) Previous Education Details :</b></h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div *ngFor="let item of userDetails?.lst">
                                        <table width="100%" border="1" cellspacing="0" cellpadding="6">
                                            <tbody>
                                                <tr>
                                                    <td width="24%"><b>Institution Name</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.INSTITUTION_NAME}}</td>
                                                    <td width="24%"><b>Country</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.EDUCATION_COUNTRY}}</td>
                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Level of Study</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%" colspan="4">{{item.LEVEL_OF_STUDY}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Start Date</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.FROM_PERIOD | date:'yyyy-MM-dd'}}</td>
                                                    <td width="24%"><b>End Date</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.TO_PERIOD | date:'yyyy-MM-dd'}}</td>

                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Examination Number e.g. KCSE index number</b>
                                                    </td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.EXAM_REG_NO}}</td>
                                                    <td width="24%"><b>Qualifications Attained</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.QUALIFICATION}}</td>

                                                </tr>
                                                <!-- <tr>
                                            <td><b>Address</b></td>
                                            <td width="2%" style="text-align: center;">:</td>
                                            <td > {{item.ADDRESS}}</td>
                                            <td><b>Additional Information</b></td>
                                            <td width="2%" style="text-align: center;">:</td>
                                            <td>{{item.ADDITIONAL_INFORMATION}}</td>
                                        </tr> -->

                                            </tbody>
                                        </table>
                                    </div>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                        style="font-size:13px;">
                                        <tbody>
                                            <tr>
                                                <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                    <h5><b>(E) Documents Uploaded :</b></h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <!-- <td><b>Upload Previous Supporting Certificates</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td></td> -->
                                                <td><b>Are you applying for Recognition of Prior Learning (RPL)?</b>
                                                </td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.is_rpl == true ? 'Yes' : 'No'}}</td>
                                            </tr>
                                            <!-- <tr>
                                        <td><b>Marital Status</b></td>
                                        <td width="2%" style="text-align: center;">:</td>
                                        <td>{{userDetails?.result?.mobile_no_emergency}}</td>
                                        <td><b>Are you applying for Recognition of Prior Learning (RPL)?</b>
                                        </td>
                                        <td width="2%" style="text-align: center;">:</td>
                                        <td>{{userDetails?.result?.address_of_emergency}}</td>
                                    </tr> -->
                                            <tr>
                                                <!-- <td><b>Upload your Portfolio Documents</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td></td> -->
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                        style="font-size:13px;">
                                        <tbody>
                                            <tr>
                                                <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                    <h5><b>(F) program selection</b></h5>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>Programme</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.result?.course_name}}</td>
                                                <td><b>Is your placement
                                                        to OUK by KUCCPS?</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.get_cluster?.IS_KUCCPS == true ? 'Yes':'No'}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Application Type</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.get_cluster?.APPLICATION_TYPE_NAME}}</td>
                                                <td><b>English</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.get_cluster?.ENGLISH_PROFICIENCY_NAME}}</td>

                                                <!-- <td width="2%" style="text-align: center;">:</td>
                                                <td></td> -->
                                            </tr>
                                            <tr *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1">
                                                <td><b>Programme Cluster
                                                    </b></td>
                                            </tr>
                                            <tr *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1">
                                                <td colspan="6">
                                                    <table width="100%" border="1" cellspacing="0" cellpadding="6">
                                                        <tbody>
                                                            <tr>
                                                                <th>&nbsp;</th>
                                                                <th>Subject Name(eg MAT)</th>
                                                                <th>Grade obtained</th>
                                                            </tr>
                                                            <tr>
                                                                <td>CLUSTER SUBJECT 1</td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT1_NAME}}
                                                                </td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT1_GRADE}}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>CLUSTER SUBJECT 2</td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT2_NAME}}
                                                                </td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT2_GRADE}}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>CLUSTER SUBJECT 3</td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT3_NAME}}
                                                                </td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT3_GRADE}}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>CLUSTER SUBJECT 4</td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT4_NAME}}
                                                                </td>
                                                                <td>{{userDetails?.get_cluster?.CLUSTER_SUBJECT4_GRADE}}
                                                                </td>
                                                            </tr>


                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            <tr>

                                                <td><b>Computing Skills</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td>{{userDetails?.get_cluster?.COMPUTING_SKILLS_PROFICIENCY_NAME}}</td>
                                                <td><b>Mode of Study</b></td>
                                                <td width="2%" style="text-align: center;">:</td>
                                                <td colspan="4">{{userDetails?.get_cluster?.MODE_OF_STUDY_NAME}}</td>
                                            </tr>

                                            <tr>
                                                <td *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1"><b>Basic
                                                        Education System</b></td>
                                                <td *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1" width="2%"
                                                    style="text-align: center;">:</td>
                                                <td *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1">
                                                    {{userDetails?.get_cluster?.BASIC_EDUCATION_SYSTEM_NAME ||
                                                    userDetails?.get_cluster?.BASIC_EDUCATION_SYSTEM_OTHER}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table width="100%" border="0" cellspacing="0" cellpadding="6">
                                        <tbody>
                                            <tr style="border-bottom:1px dashed #333; font-size:13px;">
                                                <td colspan="6">
                                                    <h5><b>(G) Working Experience</b></h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div *ngFor="let item of userDetails?.lstt">
                                        <table width="100%" border="1" cellspacing="0" cellpadding="6">
                                            <tbody>

                                                <tr>
                                                    <td width="24%"><b>Company/Employer</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.WORKEXP_DETAIL_EMPLOYER}}</td>
                                                    <td width="24%"><b>Designation</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.WORKEXP_DETAIL_DESIGNATION}}</td>

                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Start Date</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.WORKEXP_DETAIL_FROM_YEAR |
                                                        date:'yyyy-MM-dd'}}</td>
                                                    <td width="24%"><b>End Date</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.WORKEXP_DETAIL_TO_YEAR | date:'yyyy-MM-dd'}}
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>



                                    <table width="100%" border="0" cellspacing="0" cellpadding="6">
                                        <tbody>

                                            <tr style="border-bottom:1px dashed #333; font-size:13px;">
                                                <td colspan="6">
                                                    <h5><b>(H) Recommendations :</b></h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div *ngFor="let item of userDetails?.lst_recommandations">
                                        <table width="100%" border="1" cellspacing="0" cellpadding="6">
                                            <tbody>
                                                <tr>
                                                    <td width="24%"><b>Full Name</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.NAME}}</td>
                                                    <td width="24%"><b>Organization</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.ORGANIZATION}}</td>

                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Mobile Number</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.MOBILE_NUMBER}}</td>
                                                    <td width="24%"><b>City/Town</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%">{{item.CITY_TOWN}}</td>
                                                    <!-- <td width="24%"><b>Nationality</b></td>
                                        <td width="2%" style="text-align: center;">:</td>
                                        <td width="24%"> {{userDetails?.result?.sponsors_email}}</td> -->
                                                </tr>
                                                <tr>
                                                    <td width="24%"><b>Nationality</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%"> {{item.RECOMMENDATION_NATIONALITY}}</td>
                                                    <td width="24%"><b>Email Address</b></td>
                                                    <td width="2%" style="text-align: center;">:</td>
                                                    <td width="24%" colspan="4">{{item.EMAIL_ADDRESS}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="footer float-end pt-2">
                        <button id="Closemodal" class="btn btn-sm btn-danger"
                            data-bs-dismiss="modal">Close</button>&nbsp;&nbsp;
                        <button class="btn btn-save btn-sm" (click)="downloadForm()"> Download</button>
                    </div>
                </div>
                <!-- <div class="footer float-end pt-2">
                        <button class="btn btn-save btn-sm" (click)="downloadForm()"> Download</button>&nbsp;&nbsp;
                    </div> -->
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div> -->
        </div>
    </div>
</div>
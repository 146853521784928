<!-- <footer class="footer">
    <div class="d-sm-flex justify-content-center justify-content-sm-between">
      <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2020 <a
          href="http://www.dhanushinfotech.com/pages/index.htm" target="_blank">Dhanush Infotech</a>. All rights
        reserved.</span>

    </div>
  </footer> -->

  <!-- <div class="overlay toggle-icon"> -->
  <!--end overlay-->
  <!--Start Back To Top Button--> <a href="javaScript:;" class="back-to-top"><i class='bx bxs-up-arrow-alt'></i></a>
  <!--End Back To Top Button-->
  <!-- <footer class="page-footer">
    <p class="mb-0">Copyright © 2023 <a
      href="http://www.dhanushinfotech.com/pages/index.htm" target="_blank">Samvaad Infotech</a>. All right reserved.</p>
  </footer> -->
  <footer class="page-footer">
    <p class="mb-0"><a
      href="javascript:void(0)" > Copyright © {{currentYear}} Dhanush Infotech All Rights Reserved.</a> </p>
  </footer>
  
<!-- </div> -->